<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="roles" :translate-params="{id: role.id, name: role.name}">Edit Role  #%{id} - %{name}</translate>
    </h1>
    <Form :data="role" @save="onSave" :save_text="$pgettext('form','Save')" edit/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import Form from "@/domain/roles/components/Form.vue";

export default {
  name: 'EditRole',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchRole(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'roles.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('roles', [
      'role',
    ]),
  },
  methods: {
    ...mapActions('roles', [
      'fetchRole',
      'updateRole',
    ]),
    async onSave(data) {
      await this.updateRole({id: this.role.id,data: data})
      await this.$router.push({name: 'roles.list'})
      throw new SuccessNotification(this.$pgettext('roles', 'Role updated!'))
    }
  }
}
</script>

<style scoped>

</style>